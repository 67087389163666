import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';

// Styled components for consistent look
const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  padding: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 12,
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    padding: theme.spacing(2),
  },
}));

const HeroSection = () => {
  return (
    <Box sx={{ mt: 8 }}>
      <Grid container sx={{ height: { xs: 'auto', sm: '55vh' }, position: 'relative' }}>
        {/* Background Image */}
        <Grid
          item
          xs={12}
          sx={{
            position: 'relative',
            backgroundImage:
              'url(https://www.sahyadri.edu.in/_next/image?url=%2Fimages%2Fbgs%2Fhero-bg.jpg&w=3840&q=75)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'center', sm: 'space-between' },
            alignItems: 'center',
            padding: 8,
          }}
        >
          {/* Text Content */}
          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              padding: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              borderRadius: 2,
              maxWidth: { xs: '90%', sm: '60%' },
             
              marginTop:'30px'
             
            }}
          >
            <Typography
              variant="h5" // Changed from h6 to h5 for larger font
              sx={{
                fontWeight: 'bold',
                marginBottom: 0,
                fontSize: { xs: '1.5rem', sm: '2rem' }, // Increased font size
                lineHeight: 1.4,
               
              }}
            >
             Colleges in Bangalore for Engineering
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: 3,
                fontSize: { xs: '1rem', sm: '1.25rem' }, // Increased font size
                lineHeight: 1.6,
              }}
            >
              What makes Colleges in Bangalore for Engineering stand out?
              Engineering  Colleges in Bangalore  are renowned for their world-class education, exceptional faculty, and state-of-the-art infrastructure. As the Silicon Valley of India, Bangalore is home to institutions celebrated for their innovative teaching methods, cutting-edge research, and strong industry connections. Students can explore diverse engineering disciplines and benefit from outstanding placement opportunities with top global companies. Start your journey with hands-on learning and access one of the most vibrant academic environments in India.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#002366',
                color: 'white',
                fontWeight: 'bold',
                fontSize: { xs: '0.875rem', sm: '1rem' },
                '&:hover': {
                  backgroundColor: '#001a5b',
                },
              }}
            >
              Explore Programs
            </Button>
          </Box>

          {/* Form Section */}
          <FormBox
            sx={{
              position: { sm: 'absolute', xs: 'static' },
              top: { sm: '50%' },
              right: { sm: '2%' },
              transform: { sm: 'translateY(-50%)', xs: 'none' },
           marginTop:'18px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                color: '#002366',
                marginBottom: 3,
                textAlign: 'center',
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
              }}
            >
              Apply Now
            </Typography>
            <form>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ marginBottom: 3 }}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ marginBottom: 4 }}
              />
              <Button
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: '#002366',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: 4,
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  '&:hover': {
                    backgroundColor: '#001a5b',
                  },
                }}
              >
                Submit
              </Button>
            </form>
          </FormBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;
