import React, { useEffect, useState } from 'react';
import Header from './Header';
import EngineeringCollegesPage from './EngineeringCollegesPage';
import CollegeList from './CollegeList';
import AboutUs from './AboutUs';
import Footer from './Footer';
import AutoPopup from './AutoPopup';
import FeaturesGrid from './FeaturesGrid';
const App = () => {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);

  const handleOpenAutoPopup = () => {
    const popupCount = parseInt(sessionStorage.getItem("popupCount") || "0", 10);

    if (popupCount < 2) {
      setIsAutoPopupOpen(true);
      sessionStorage.setItem("popupCount", (popupCount + 1).toString());
    }
  };

  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);
    return () => clearTimeout(timeout); // Cleanup timeout to avoid memory leaks
    }, []);
  return (
    <div>
      <Header/>
      <EngineeringCollegesPage />
      <CollegeList />
      <FeaturesGrid/>
      <AboutUs />
     
      <Footer />

      <AutoPopup
        isOpen={isAutoPopupOpen}
        onClose={handleCloseAutoPopup}
        leadFlag="Organic"
      />

    </div>
  );
};

export default App;
