import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import ScienceIcon from '@mui/icons-material/Science';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import EventIcon from '@mui/icons-material/Event';
import WorkIcon from '@mui/icons-material/Work';

const features = [
  {
    title: 'Industry Connections',
    description:
      'Engineering colleges in Bangalore have strong ties with industries and companies in various sectors. These connections provide students with internship opportunities, industry exposure, and a competitive edge in the job market.',
    icon: <BusinessIcon sx={{ color: '#002366', fontSize: 50,textAlign:'justify' }} />,
  },
  {
    title: 'Global Exposure',
    description:
      'Many colleges offer international collaborations, student exchange programs, and exposure to global standards in engineering education. This helps students develop a global perspective and enhances career opportunities abroad.',
    icon: <PublicIcon sx={{ color: '#002366', fontSize: 50 }} />,
  },
  {
    title: 'Innovation and Research Focus',
    description:
      'Bangalore’s engineering colleges prioritize innovation and research by encouraging students to participate in cutting-edge projects and solve real-world challenges. Dedicated research centers and funding support foster creativity.',
    icon: <ScienceIcon sx={{ color: '#002366', fontSize: 50 }} />,
  },
  {
    title: 'Entrepreneurial Ecosystem',
    description:
      'With Bangalore being a hub for startups, engineering colleges promote entrepreneurship through incubation centers, mentorship programs, and access to venture capitalists, enabling students to turn their ideas into successful businesses.',
    icon: <LightbulbIcon sx={{ color: '#002366', fontSize: 50 }} />,
  },
  {
    title: 'Cultural and Extracurricular Activities',
    description:
      'Students enjoy a rich cultural environment with numerous clubs, fests, and extracurricular activities. These events help in developing soft skills, leadership qualities, and a well-rounded personality. Additionally, they provide a platform for students to showcase their talents.',
    icon: <EventIcon sx={{ color: '#002366', fontSize: 50,textAlign:'justify' }} />,
  },
  {
    title: 'Placement Opportunities',
    description:
      'Bangalore’s engineering colleges have excellent placement records, partnering with top multinational companies and startups. Students benefit from placement training, mock interviews, and job-oriented workshops to secure promising careers.',
    icon: <WorkIcon sx={{ color: '#002366', fontSize: 50 }} />,
  },
];

const FeaturesGrid = () => {
  return (
    <Box sx={{ p: 4, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Advantages of Engineering Colleges in Bangalore
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" mb={4}>
        Explore the key benefits that set engineering colleges apart.
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              elevation={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'justify',
                p: 3,
                height: '84%',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <Box mb={3}>{feature.icon}</Box>
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturesGrid;
