import React from 'react';
import { Box, Typography, Link, IconButton, Tooltip } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#002366',
        color: '#fff',
        textAlign: 'center',
        padding: { xs: '20px 0', sm: '30px 0' },
        position: 'relative',
        bottom: 0,
        width: '100%',
        mt: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
        px: { xs: '-1px', sm: '-1px' },
        boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      {/* Left side: Copyright */}
      <Typography
        variant="body2"
        sx={{
          fontSize: { xs: '14px', sm: '16px' },
          fontWeight: '400',
          animation: 'fadeIn 1s ease-out',
        }}
      >
        © {new Date().getFullYear()}{' '}
        <Link
          href="/"
          color="inherit"
          sx={{
            textDecoration: 'none',
            '&:hover': { color: '#00A8FF', textDecoration: 'underline' },
            transition: 'color 0.3s ease',
          }}
        >
          ExploreTopEngineeringColleges.com
        </Link>
        . All Rights Reserved. Designed by Keerthi Kumar M
      </Typography>

      {/* Center: Quick Links */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          gap: 2,
          mt: { xs: 2, sm: 0 },
        }}
      >
        <Link
          href="/privacy-policy"
          color="inherit"
          sx={{
            textDecoration: 'none',
            '&:hover': { color: '#00A8FF', textDecoration: 'underline' },
            fontSize: { xs: '14px', sm: '16px' },
            transition: 'color 0.3s ease',
          }}
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms-of-service"
          color="inherit"
          sx={{
            textDecoration: 'none',
            '&:hover': { color: '#00A8FF', textDecoration: 'underline' },
            fontSize: { xs: '14px', sm: '16px' },
            transition: 'color 0.3s ease',
          }}
        >
          Terms of Service
        </Link>
        <Link
          href="/contact"
          color="inherit"
          sx={{
            textDecoration: 'none',
            '&:hover': { color: '#00A8FF', textDecoration: 'underline' },
            fontSize: { xs: '14px', sm: '16px' },
            transition: 'color 0.3s ease',
          }}
        >
          Contact Us
        </Link>
      </Box>

      {/* Right side: Social Media */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          mt: { xs: 2, sm: 0 },
        }}
      >
        <Tooltip title="Facebook" arrow>
          <IconButton
            href="https://www.facebook.com"
            target="_blank"
            sx={{ color: '#fff', '&:hover': { color: '#00A8FF' } }}
          >
            <FacebookIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Twitter" arrow>
          <IconButton
            href="https://www.twitter.com"
            target="_blank"
            sx={{ color: '#fff', '&:hover': { color: '#00A8FF' } }}
          >
            <TwitterIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="LinkedIn" arrow>
          <IconButton
            href="https://www.linkedin.com"
            target="_blank"
            sx={{ color: '#fff', '&:hover': { color: '#00A8FF' } }}
          >
            <LinkedInIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Instagram" arrow>
          <IconButton
            href="https://www.instagram.com"
            target="_blank"
            sx={{ color: '#fff', '&:hover': { color: '#00A8FF' } }}
          >
            <InstagramIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Email" arrow>
          <IconButton
            href="mailto:info@exploretopengineeringcolleges.com"
            sx={{ color: '#fff', '&:hover': { color: '#00A8FF' } }}
          >
            <EmailIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Footer;
