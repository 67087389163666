import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ramm from '../assets/ramm.png';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navigationItems = [
    { label: 'Home', url: 'https://www.getmycollege.com/' },
    { label: 'About', url: 'https://www.getmycollege.com/about-us' },
    { label: 'Colleges', url: 'https://www.getmycollege.com/colleges' },
    { label: 'Courses', url: 'https://www.getmycollege.com/colleges?state=karnataka&stream=-engineering&courses=b%20e%20computer%20science%20and%20engineering' },
    { label: 'Careers', url: 'https://www.getmycollege.com/careers' },
    { label: 'Contact', url: 'https://www.getmycollege.com/contact-us' },
  ];

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#002366', boxShadow: 3 }}>
      <Container disableGutters maxWidth={false}>
        <Toolbar sx={{ justifyContent: 'space-between', px: 2 }}>
          {/* Logo Section */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component="img"
              src={ramm}
              alt="Website Logo"
              sx={{
                height: { xs: 60, md: 100 }, // Adjust logo height for better visibility on mobile
                width: { xs: '200px', md: '430px' }, // Adjust logo width for better scaling
              }}
            />
          </Box>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3 }}>
            {navigationItems.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleNavigation(item.url)}
                sx={{
                  fontWeight: 900, // Set font weight to 900 for even bolder text
                  color: '#FFFFFF',
                  textTransform: 'capitalize',
                  fontSize: '1.1rem', // Increased font size
                  '&:hover': {
                    color: '#FFCC00',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.3s ease',
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            color="inherit"
            sx={{ display: { xs: 'flex', md: 'none' } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>

      {/* Mobile Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            backgroundColor: '#002366',
            height: '100%',
            color: '#FFFFFF',
            padding: 2,
          }}
          role="presentation"
        >
          <IconButton
            edge="start"
            onClick={toggleDrawer(false)}
            sx={{ position: 'absolute', top: 10, right: 10, color: '#FFCC00' }}
          >
            <CloseIcon />
          </IconButton>
          <List>
            {navigationItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => handleNavigation(item.url)}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FFCC00',
                      color: '#002366',
                    },
                    padding: '16px', // Add some padding for mobile touch targets
                  }}
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{ fontWeight: 900, fontSize: '1rem' }} // Keep font size slightly larger for mobile as well
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
