import React from 'react';
import { Typography, Grid, Button, Card } from '@mui/material';

const AboutUs = () => {
  return (
    <div style={{ backgroundColor: '#f4f4f4', color: '#333', padding: '20px 10px' }}>
      {/* Header Section */}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: '#00A8FF', lineHeight: '1.4', fontSize: '2.5rem' }}
        >
          About Engineering Colleges in Banglore
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: '#666',
            lineHeight: '1.6',
            padding: '0 10px',
            fontSize: '1.2rem',
            textAlign: 'justify',
            marginLeft:'10px'
          }}
        >
          Discover the Colleges in Bangalore for Engineering, home to top-tier institutions offering world-class education and advanced facilities. Explore diverse disciplines like Computer Science, Mechanical, Electronics, and Civil Engineering. With strong industry connections, hands-on learning, and high placement rates, Bangalore ensures a bright future for aspiring engineers. Benefit from expert guidance, detailed insights, and seamless support to find the college best suited to your aspirations. Kickstart your engineering journey in one of India’s most vibrant educational hubs.
        </Typography>
      </div>

      {/* Layout with Content and Image */}
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        {/* Left Content */}
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ color: '#333', fontSize: '2rem' }}
          >
            Why Choose Us?
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{
              color: '#666',
              lineHeight: '1.6',
              fontSize: '1.1rem',
              marginRight: '15px',
              marginLeft: '15px', // Adjusted margins as per "college list" component
              textAlign: 'justify',
            }}
          >
Bangalore, often called the Silicon Valley of India, is a premier destination for engineering education. Our platform highlights the engineering colleges in Bangalore, showcasing their academic excellence, state-of-the-art infrastructure, and industry-ready programs. Whether you aspire to excel in cutting-edge fields like Artificial Intelligence and Robotics or traditional disciplines like Mechanical and Civil Engineering, these institutions offer the perfect launchpad for your career. Explore colleges with high placement records, hands-on learning opportunities, and strong industry connections, ensuring a bright and successful engineering journey.          </Typography>

          <ul
            style={{
              listStyleType: 'disc',
              margin: '0 auto',
              padding: '0 20px',
              textAlign: 'left',
              maxWidth: '500px',
              color: '#666',
              lineHeight: '1.8',
              fontSize: '1.1rem',
            }}
          >
            <li>Top-tier institutions recognized for academic excellence.</li>
            <li>State-of-the-art infrastructure and modern labs.</li>
            <li>Strong industry tie-ups ensuring internship and placement opportunities.</li>
            <li>Diverse disciplines like AI, Robotics, Mechanical, and more.</li>
            <li>Comprehensive support for career guidance and personal development.</li>
          </ul>
        </Grid>

        {/* Right Image */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              width: { xs: '100%', sm: '500px', md: '600px' },
              height: { xs: 200, sm: 300, md: 400 },
              backgroundImage:
                'url("https://svmsolutions.com/wp-content/uploads/2020/01/converting-about-us-page.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '10px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              margin: '0 auto',
            }}
          />
        </Grid>
      </Grid>

      {/* CTA Button */}
      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <Button
          href="https://www.getmycollege.com/about-us"
          variant="contained"
          sx={{
            backgroundColor: '#00A8FF',
            color: '#fff',
            padding: '12px 25px',
            fontSize: '1.1rem',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#008CCF',
            },
          }}
        >
          Learn More →
        </Button>
      </div>
    </div>
  );
};

export default AboutUs;
